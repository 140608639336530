<template>
    <div>
        <!-- /.modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <form @submit.prevent="save" class="card">
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title" id="exampleModalLabel"> <img src="../../../public/sensor.png"> {{ form.id && isDetail ? "Detail Payment Gateway" : form.id ? "Edit Payment Gateway" : "Add Payment Gateway"}}</h5>
                        </div>
                        <div class="modal-body">
                            <div class="col">
                                <div class="form-group">
                                    <label for="code" class="col-form-label">Name Payment Gateway<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" id="name" required v-model="form.name" placeholder="Ex. Gatepay"
                                        :disabled="loading || isDetail">
                                </div>
                                <div class="form-group">
                                    <label for="title" class="col-form-label">Code Payment Gateway<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" required id="code" v-model="form.code" placeholder="Ex. 123ABC"
                                         :disabled="loading || isDetail">
                                </div>
                                <div class="form-group">
                                    <label for="title" class="col-form-label">URL</label>
                                    <input type="text" class="form-control" id="url" v-model="form.url"
                                         :disabled="loading || isDetail">
                                </div>
                                <div class="form-group">
                                    <label for="title" class="col-form-label">URL 2 (Ottopay Only)</label>
                                    <input id="url-2" v-model="form.url_2" class="form-control" type="text"
                                         :disabled="loading || isDetail">
                                </div>
                                <div class="row">
                                <div class="col-4">
                                    <!-- <div class="form-group" v-if="error && !form.image" id="div_image" @click="defaultBtnImage()" style="cursor: pointer; border: 1px solid red">
                                        <img :src="form.image" v-if="form.image" width="100%" height="100%" class="img-thumbnail" />
                                        <img src="@assets/no-image.png" v-else width="100%" height="100%" class="img-thumbnail" alt="No Image">
                                        <input type="file" accept="image/x-png,image/jpeg" id="default-btn"  @change="handleImage" :disabled="loading" hidden />
                                        <div class="bg-primary text-center p-2" >
                                        Upload Photo<span class="text-red">*</span>
                                        </div> -->
                                        <!-- <span v-if="error && !form.image" style="color: red;">*{{ error }}</span> -->
                                    <!-- </div> -->
                                    <div class="form-group" id="div_image" @click="defaultBtnImage()" style="cursor: pointer;">
                                        <img :src="form.image" v-if="form.image" width="100%" height="100%" class="img-thumbnail" />
                                        <img src="@assets/no-image.png" v-else width="100%" height="100%" class="img-thumbnail" alt="No Image" style="border: 1px solid red">
                                        <input type="file" accept="image/x-png,image/jpeg" id="default-btn"  @change="handleImage" :disabled="loading" hidden />
                                        <div class="bg-primary text-center p-2" >
                                         Upload Photo<span class="text-red">*</span>
                                        </div>
                                    </div>
                                </div>
                            <div class="col-4">
                                <div>
                                    <span class="text-red">*</span> Photo max {{ maxSizeText }}
                                </div>
                                <span class="text-red">*</span> File Photo JPG or PNG
                            </div>
                        </div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="resetPaymentGateway">Close</button>
                            <div v-if="!isDetail">
                                <button type="submit" class="btn btn-primary" :disabled="loading">
                                    <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                                    {{ loading ? 'Saving ...' : 'Save' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <form @submit.prevent="deleteData" class="card">
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title" id="deleteModalLabel"> <img src="../../../public/sensor.png"> Delete Payment Gateway</h5>
                        </div>
                        <div class="modal-body">
                            <div class="col">
                                <div class="form-group">
                                    <span>Are you sure you want to delete?</span>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <div v-if="!isDetail">
                                <button type="submit" class="btn btn-primary" :disabled="loading">
                                    <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                                    {{ loading ? 'Deleting...' : 'Delete' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark capitalize">
                            Payment Gateway
                        </h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right ">
                            <li>
                                <button type="button" class="btn btn-block btn-outline-primary btn-lg">Export</button>
                            </li>
                            <li class="ml-3"><button type="button" class="btn btn-block btn-primary btn-lg"
                                    @click="showModal"><span class="fas fa-plus"> </span> Add Payment Gateway</button></li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <data-tables-master-data :config="config" class="container-fluid" id="sampleTable"></data-tables-master-data>

    </div>
</template>


<script>
import DataTablesMasterData from '@components/DataTablesMasterData.vue';

const {console} = window
export default {
    components: {
        DataTablesMasterData,
    },

    data() {
        return {
            active: false,
            uploadFieldName: 'file',
            loading: false,
            text_validation: null,
            maxSize: 1024,
            maxSizeText: "1 MB",
            form: {
                id: null,
                code: "",
                name: "",
                image: "",
                url: "",
                url_2: "",
            },
            disabled: false,
            isDetail : false,
            config: {
                columns: [{
                    title: 'Nama Payment Gateway',
                    data: 'name',
                    render: (val, i, row) => {
                        return `<img src="${row.image}" width="46px" height="48px"> ${val}`
                    }
                }, {
                    title: 'Code Payment Gateway',
                    data: 'code'
                }, {
                    title: 'Action',
                    data: 'id',
                    // ordering: false,
                    render: (val) => {
                        return `
                        <div> 
                            <a 
                                href="javascript:void(0);" 
                                data-id="${val}" 
                                class="editor-detail" 
                                data-action="detail" 
                                style="color:#9E9E9E;margin-right:10px;"
                            >
                                <i class="fa fa-eye"></i>
                            </a>
                            <a 
                                href="javascript:void(0);" 
                                data-id="${val}" 
                                class="editor-edit" 
                                data-action="edit"
                                style="color:#9E9E9E;margin-right:10px;"
                            >
                                <i class="fa fa-edit"></i>
                            </a>
                            <a 
                                href="javascript:void(0);" 
                                data-id="${val}" 
                                data-action="delete" 
                                class="editor-delete" 
                                style="color:#9E9E9E;"
                            >
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>`
                    }
                }],
                "drawCallback": ( settings ) => {
                        $(".editor-detail").on( 'click', (e) => {
                            this.getDetail($(e.target).closest('a').data('id'))
                        });

                        $(".editor-edit").on( 'click', (e) => {
                            this.getEdit($(e.target).closest('a').data('id'))
                        });
                        $(".editor-delete").on( 'click', (e) => {
                            this.showModalDelete($(e.target).closest('a').data('id'))
                        });

                },
                url: this.Api.base + '/payoutmethod',
                order: [[0, "desc"]],
            }
        }
    },
    mounted() {
        this.getDetail();
    },
    props: {
        value: Object,
    },
    methods: {
        getDetail(id) {
            if(id) {
                this.Api.get(`/payoutmethod/${id}`).then(res =>{
                    if(res.data) {
                        this.payout = res.data
                        this.form.id= this.payout.id;
                        this.form.code=this.payout.code;
                        this.form.name=this.payout.name;
                        this.form.image=this.payout.image;
                        this.form.url=this.payout.url;
                        this.form.url_2=this.payout.url_2;
                        this.isDetail = true
                        $('#exampleModal').modal('show');
                    }
                })
                .catch((e) =>{
                    console.log(e)
                })  
            }
        },
        getEdit(id) {
            if(id) {
                this.Api.get(`/payoutmethod/${id}`).then(res =>{
                    if(res.data) {
                        this.payout = res.data
                        this.form.id= this.payout.id;
                        this.form.code=this.payout.code;
                        this.form.name=this.payout.name;
                        this.form.image=this.payout.image;
                        this.form.url=this.payout.url;
                        this.form.url_2=this.payout.url_2;
                        this.isDetail = false
                        $('#exampleModal').modal('show');
                    }
                })
                .catch((e) =>{
                    console.log(e)
                })  
            }
        },
        showModalDelete(id) {
            $('#deleteModal').modal('show');
            this.form.id = id
        },
        deleteData() { 
            this.loading = true
            if(this.form.id) {
                this.Api.delete(`/payoutmethod/${this.form.id}`).then(res => {
                    this.loading = false;
                    this.refresh();
                    this.resetPaymentGateway()
                    $('#deleteModal').modal('hide');

                    let successTitle = "Delete Success";
                    let successMessage = "Data payment gateway berhasil dihapus";
                    this.showSuccessAlert(successTitle, successMessage);
                }).catch((e) => {
                    this.loading = false
                    
                    let errorTitle = "Delete Failed";
                    let errorMessage = e.response?.data?.message;
                    this.showErrorAlert(errorTitle, errorMessage);
                })
            }
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            if(selectedImage.type === "image/png" || selectedImage.type === "image/jpeg") {
              if(selectedImage.size <= this.maxSize * this.maxSize) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.form.image = e.target.result;
                }
                reader.readAsDataURL(selectedImage);
              } else {
                let errorTitle = "Submit Failed";
                let errorMessage = `File too big (> ${this.maxSizeText})`;
                this.showErrorAlert(errorTitle, errorMessage);    
              }
            } else {
                let errorTitle = "Submit Failed";
                let errorMessage = `File is not supported, please use JPG or PNG format`;
                this.showErrorAlert(errorTitle, errorMessage);   
            }
        },
        resetPaymentGateway() {
            this.form = {
                id: null,
                code : "",
                name : "",
                image: "",
                url : "",
                url_2 : "",
            }
        },
        showModal() {
            this.isDetail = false;
            this.resetPaymentGateway();
            $("#exampleModal").modal("show");
        },
        defaultBtnImage() {
            const defaultbtn = document.querySelector("#default-btn");
            defaultbtn.click();
        },
        save() {
            this.loading = true
            if(this.form.id === null) {
                this.Api.post('/payoutmethod', this.form).then(res => {
                    this.loading = false
                    this.refresh();
                    this.resetPaymentGateway()
                    $('#exampleModal').modal('hide');

                    let successTitle = "Submit Success";
                    let successMessage = "Data payment gateway tersimpan";
                    this.showSuccessAlert(successTitle, successMessage);
                }).catch((e) => {
                    this.loading = false
           
                    let errorTitle = "Submit Failed";
                    let errorMessage = e.response?.data?.message;
                    this.showErrorAlert(errorTitle, errorMessage);         
                })
            } else {
                this.Api.put(`/payoutmethod/${this.form.id}`, this.form).then(res => {
                    this.loading = false
                    this.refresh();
                    this.resetPaymentGateway()
                    $('#exampleModal').modal('hide');

                    let successTitle = "Submit Success";
                    let successMessage = "Data payment gateway tersimpan";
                    this.showSuccessAlert(successTitle, successMessage);
                }).catch((e) => {
                    this.loading = false
                    
                    let errorTitle = "Submit Failed";
                    let errorMessage = e.response?.data?.message;
                    this.showErrorAlert(errorTitle, errorMessage);
                })
            }
        },
        refresh() {
            this.config = {
                url: this.Api.base + '/payoutmethod'
            }
        },
        showErrorAlert(errorTitle, errorMessage) {
            alert(`${errorTitle}, ${errorMessage}`)
        },
        showSuccessAlert(successTitle, successMessage) {
            alert(`${successTitle}, ${successMessage}`)
        },
    },
}
</script>
